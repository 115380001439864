import { KeyboardTimePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { updateProviderProfile } from "../../services/provider";
import { shallowEqual, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { toast } from "react-toastify";
export const Hours = ({ hours_list, providerId, fetchProfile }: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const userData = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const tempHours = [
    {
      id: "MON",
      active: false,
      label: "Monday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
    {
      id: "TUE",
      active: false,
      label: "Tuesday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
    {
      id: "WED",
      active: false,
      label: "Wednesday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
    {
      id: "THU",
      active: false,
      label: "Thursday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
    {
      id: "FRI",
      active: false,
      label: "Friday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
    {
      id: "SAT",
      active: false,
      label: "Saturday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
    {
      id: "SUN",
      active: false,
      label: "Sunday",
      startHour: "07:00 AM",
      endHour: "07:00 PM",
    },
  ];
  const [hours, setHours]: any = useState(
    hours_list.length > 0 ? hours_list : tempHours
  );
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (
      userData &&
      userData.clinics[0] &&
      userData.clinics[0].hours &&
      userData.clinics[0].hours.length > 0
    ) {
      const hoursClinic = userData.clinics[0].hours;
      const doctorHour = hours_list.length > 0 ? hours_list : tempHours;
      setHours(
        doctorHour.map((item: any, index: number) => {
          if (hoursClinic[index].startHour === "Closed") {
            return {
              ...item,
              active: false,
              clinicClose: true,
              startHour: "Clinic closed",
              endHour: "Clinic closed",
            };
          }
          return {
            ...item,
          };
        })
      );
    } else {
      setHours(hours_list.length > 0 ? hours_list : tempHours);
    }
    console.log("hours List", hours_list);
  }, [hours_list, userData]);
  const handleDateChange = (date: any, hIndex: number, index: number) => {
    if (hIndex === 0) {
      let selectedDay: any = hours.find(
        (item: any, hIndex: number) => hIndex === index
      );
      let clinicHour: any = userData.clinics[0].hours.find(
        (item: any) => item.id === selectedDay.id
      );
      if (
        moment(clinicHour.startHour, "LT").isAfter(
          moment(moment(date).format("LT"), "LT")
        )
      ) {
        toast.warn("Start time should be next to clinic start time.");
        return;
      } else if (
        moment(clinicHour.endHour, "LT").isBefore(
          moment(moment(date).format("LT"), "LT")
        )
      ) {
        toast.warn("clinic end time at " + clinicHour.endHour);
        return;
      }
      const mappedHours = hours.map((hItem: any, hIndex: number) => {
        if (hIndex === index) {
          toCloseTimePicker = true;
          return {
            ...hItem,
            openStart: false,
            startHour: moment(date).format("LT"),
            startShow: date,
            endHour: moment(date).format("LT"),
            endShow: date,
          };
        }
        return hItem;
      });
      setHours(mappedHours);
    } else {
      let selectedDay: any = hours.find(
        (item: any, hIndex: number) => hIndex === index
      );
      let clinicHour: any = userData.clinics[0].hours.find(
        (item: any) => item.id === selectedDay.id
      );
      if (
        moment(selectedDay.startHour, "LT").isAfter(
          moment(moment(date).format("LT"), "LT")
        )
      ) {
        toast.warn("End time shoud be next to start time");
        return;
      } else if (
        moment(clinicHour.endHour, "LT").isBefore(
          moment(moment(date).format("LT"), "LT")
        )
      ) {
        toast.warn("clinic end time at " + clinicHour.endHour);
        return;
      }
      const mappedHours = hours.map((hItem: any, hIndex: number) => {
        if (hIndex === index) {
          toCloseTimePicker = true;
          return {
            ...hItem,
            openEnd: false,
            endHour: moment(date).format("LT"),
            endShow: date,
          };
        }
        return hItem;
      });
      setHours(mappedHours);
    }
  };
  const onCancel = () => {
    setIsEdit(false);
    if (
      userData &&
      userData.clinics[0] &&
      userData.clinics[0].hours &&
      userData.clinics[0].hours.length > 0
    ) {
      const hoursClinic = userData.clinics[0].hours;
      const doctorHour = hours_list.length > 0 ? hours_list : tempHours;
      setHours(
        doctorHour.map((item: any, index: number) => {
          if (hoursClinic[index].startHour === "Closed") {
            return {
              ...item,
              active: false,
              clinicClose: true,
              startHour: "Clinic closed",
              endHour: "Clinic closed",
            };
          }
          return {
            ...item,
          };
        })
      );
    } else {
      setHours(hours_list.length > 0 ? hours_list : tempHours);
    }
  };
  const onSave = () => {
    setIsEdit(true);
    const foundIndex = hours.findIndex((item: any) =>
      moment(item.startHour, "LT").isAfter(moment(item.endHour, "LT"))
    );
    if (foundIndex !== -1) {
      setError(
        hours[foundIndex].label +
          "'s start time is after end time, please verify."
      );
      return;
    }
    setError("");
    setIsLoading(true);
    updateProviderProfile(
      "hours",
      providerId,
      hours.map((item: any) => ({ ...item, active: true }))
    )
      .then((success) => {
        if (success && success.status && success.status.error === false) {
          fetchProfile("hour").then((success: any) => {
            setIsEdit(false);
            setTimeout(() => {
              setIsLoading(false);
              setIsEdit(false);
            }, 2000);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  let toCloseTimePicker = false;
  return (
    <div className="practice-hours-sec mb-4">
      <div
        className={"card " + (hours_list.length === 0 ? "border-danger" : "")}
      >
        <div className="card-header bg-light py-3 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Provider hours</div>
            <div>
              {isEdit && !isLoading && (
                <>
                  <button
                    className="btn p-0 btn-link me-2 text-secondary"
                    onClick={(e) => {
                      onCancel();
                    }}
                  >
                    <i className="bi bi-x-circle-fill text-danger"></i>
                  </button>
                  <button
                    className="btn p-0 btn-link"
                    onClick={(e) => {
                      onSave();
                    }}
                  >
                    <i className="bi bi-check-circle-fill text-success"></i>
                  </button>
                </>
              )}
              {isEdit && isLoading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
              {isLoading && !isEdit && (
                <i className="bi bi-check-circle-fill text-success"></i>
              )}
              {!isEdit && !isLoading && (
                <button
                  className="btn p-0 btn-link"
                  onClick={(e) => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>

        {error !== "" && <p>{error}</p>}
        <ul className="list-group list-group-flush">
          {Array.isArray(hours) &&
            hours.map((hour: any, index: number) => {
              if (isEdit) {
                return (
                  <li
                    className="list-group-item py-3 px-4"
                    key={index + hour.id}
                  >
                    <div className="day-title fw-medium align-self-center mb-1">
                      {hour.label}
                    </div>
                    <div className="d-flex">
                      <div className="input-group flex-nowrap">
                        <input
                          type="text"
                          className="form-control border-end-0"
                          placeholder="Open"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={hour.startHour}
                          disabled
                        />
                        <span
                          className="input-group-text bg-white"
                          id="basic-addon2"
                        >
                          {hour.startHour !== "Closed" && !hour.clinicClose && (
                            <i
                              className="bi bi-x me-2 cursor-pointer"
                              onClick={() => {
                                if (hour.clinicClose) {
                                  return;
                                }
                                setHours(
                                  hours.map((hItem: any, hIndex: number) => {
                                    if (index === hIndex) {
                                      return {
                                        ...hItem,
                                        startHour: "Closed",
                                        endHour: "Closed",
                                      };
                                    }
                                    return hItem;
                                  })
                                );
                              }}
                            ></i>
                          )}
                          {!hour.clinicClose && (
                            <i
                              className="bi bi-clock cursor-pointer"
                              onClick={(e: any) => {
                                if (hour.clinicClose) {
                                  return;
                                }
                                setHours(
                                  hours.map((hItem: any, hIndex: number) => {
                                    if (hIndex === index) {
                                      return {
                                        ...hItem,
                                        openStart: true,
                                      };
                                    }
                                    return {
                                      ...hItem,
                                      openStart: false,
                                    };
                                  })
                                );
                              }}
                            ></i>
                          )}
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Time picker"
                            value={hour.startShow}
                            onChange={(date) => {
                              handleDateChange(date, 0, index);
                            }}
                            TextFieldComponent={(props) => {
                              return null;
                            }}
                            open={hour.openStart ? true : false}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                            onClose={() => {
                              if (!toCloseTimePicker) {
                                setTimeout(() => {
                                  setHours(
                                    hours.map((hItem: any, hIndex: number) => {
                                      if (hIndex === index) {
                                        return {
                                          ...hItem,
                                          openStart: false,
                                        };
                                      }
                                      return hItem;
                                    })
                                  );
                                }, 1000);
                                toCloseTimePicker = true;
                              }
                            }}
                          />
                        </span>
                      </div>
                      <div className="mx-2 align-self-center text-muted">
                        To
                      </div>
                      <div className="input-group flex-nowrap">
                        <input
                          type="text"
                          className="form-control border-end-0"
                          placeholder="Closed"
                          value={hour.endHour}
                          disabled
                        />
                        <span className="input-group-text bg-white">
                          {hour.endHour !== "Closed" && !hour.clinicClose && (
                            <i
                              className="bi bi-x me-2 cursor-pointer"
                              onClick={() => {
                                if (hour.clinicClose) {
                                  return;
                                }
                                setHours(
                                  hours.map((hItem: any, hIndex: number) => {
                                    if (index === hIndex) {
                                      return {
                                        ...hItem,
                                        startHour: "Closed",
                                        endHour: "Closed",
                                      };
                                    }
                                    return hItem;
                                  })
                                );
                              }}
                            ></i>
                          )}
                          {!hour.clinicClose && (
                            <i
                              className="bi bi-clock cursor-pointer"
                              onClick={() => {
                                if (hour.clinicClose) {
                                  return;
                                }
                                setHours(
                                  hours.map((hItem: any, hIndex: number) => {
                                    if (hIndex === index) {
                                      return {
                                        ...hItem,
                                        openEnd: true,
                                      };
                                    }
                                    return {
                                      ...hItem,
                                      openEnd: false,
                                    };
                                  })
                                );
                              }}
                            ></i>
                          )}
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Time picker"
                            value={hour.endShow}
                            onChange={(date) => {
                              handleDateChange(date, 1, index);
                            }}
                            TextFieldComponent={(props) => {
                              return null;
                            }}
                            open={hour.openEnd ? true : false}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                            onClose={() => {
                              if (!toCloseTimePicker) {
                                setTimeout(() => {
                                  setHours(
                                    hours.map((hItem: any, hIndex: number) => {
                                      if (hIndex === index) {
                                        return {
                                          ...hItem,
                                          openEnd: false,
                                        };
                                      }
                                      return hItem;
                                    })
                                  );
                                }, 1000);
                                toCloseTimePicker = true;
                              }
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </li>
                );
              }
              return (
                <li
                  className="list-group-item py-3 px-4 d-flex justify-content-between"
                  key={hour.id}
                >
                  <small className="align-self-center text-muted">
                    {hour.label}
                  </small>
                  {(hour.startHour === "Closed" ||
                    hour.startHour === "Clinic closed") && (
                    <span className="fw-medium">{hour.startHour}</span>
                  )}
                  {hour.startHour !== "Closed" &&
                    hour.startHour !== "Clinic closed" && (
                      <span className="fw-medium">
                        {hour.startHour} - {hour.endHour}
                      </span>
                    )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
