import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { IAppState } from "../../../../store";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import { AcceptNewPatient } from "../../../components/provider/AcceptNewPatient";
import { Description } from "../../../components/provider/Description";
import { Hours } from "../../../components/provider/Hours";
import { PMSMapping } from "../../../components/provider/PMSMapping";
import { Questions } from "../../../components/provider/Questions";
import { Services } from "../../../components/provider/Services";
import { ShareCalendar } from "../../../components/provider/ShareCalendar";
import { Speciality } from "../../../components/provider/Speciality";
import { TitleBox } from "../../../components/provider/TitleBox";
import { getProviderProfile } from "../../../services/provider";
import {
  getQuestionsList,
  getSpecialityList,
  getServiceList,
  getLanguagesList,
  getPMSProviderList,
} from "../../../services/providerOnboard";
import { EducationCertifications } from "./EducationCertifications";
import { GeneralInfo } from "./GeneralInfo";
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import { BlockTime } from "../../../components/provider/BlockTime";
import SignInInformaion from "../../../components/provider/SignInInformaion";
import configs from "../../../configs/apiConfigs";
export const Profile = () => {
  const match: any = useRouteMatch();
  const [profile, setProfile]: any = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [masterPMSProviderList, setMasterPMSProviderList] = useState([]);
  const userDataResponse = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    getProfile();
    getQuesList();
    getSpecialityListFunc();
    getLanguagesList().then((success) => {
      if (success && success.status && success.status.error === false) {
        setLanguageList(
          success.languages.map((item: any) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
    });
    getPMSProviderList(userDataResponse.clinics[0].id).then((success) => {
      if (success && success.status && success.status.error === false) {
        setMasterPMSProviderList(success.pmsProviders);
      }
    });
  }, []);
  useEffect(() => {
    if (profile) {
      getSericeListFunc();
    }
  }, [profile]);
  const getProfile = async () => {
    dispatch(fetchLoadingPending());
    getProviderProfile(match.params.Id)
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          console.log("provider Profile", success);
          setProfile(success);
          return new Promise((res, rej) => {
            res(true);
          });
        }
        return new Promise((res, rej) => {
          rej(true);
        });
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        return new Promise((res, rej) => {
          rej(true);
        });
      });
  };
  const getQuesList = () => {
    dispatch(fetchLoadingPending());
    getQuestionsList()
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setQuestionList(success.questions);
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
  };
  const getSpecialityListFunc = () => {
    dispatch(fetchLoadingPending());
    getSpecialityList(userDataResponse.clinics[0].id)
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setSpecialityList(success.speciality);
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
  };
  const getSericeListFunc = () => {
    dispatch(fetchLoadingPending());
    getServiceList(profile.specialities.map((item: any) => item.id).toString())
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setServiceList(success.services);
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
  };
  const previewClick = () => {
    let firstField = "";
    let secondField = "";
    if (userDataResponse?.clinics?.[0]?.address) {
      if (
        userDataResponse?.clinics?.[0]?.address.sublocality &&
        userDataResponse?.clinics?.[0]?.address.sublocality !== ""
      ) {
        firstField = userDataResponse?.clinics?.[0]?.address.sublocality;
      } else if (
        userDataResponse?.clinics?.[0]?.address.neigborhood &&
        userDataResponse?.clinics?.[0]?.address.neigborhood !== ""
      ) {
        firstField = userDataResponse?.clinics?.[0]?.address.neigborhood;
      } else if (
        userDataResponse?.clinics?.[0]?.address.locality &&
        userDataResponse?.clinics?.[0]?.address.locality !== ""
      ) {
        firstField = userDataResponse?.clinics?.[0]?.address.locality;
      }

      if (
        userDataResponse?.clinics?.[0]?.address.address_level_1_short &&
        userDataResponse?.clinics?.[0]?.address.address_level_1_short !== ""
      ) {
        secondField =
          userDataResponse?.clinics?.[0]?.address.address_level_1_short;
      }
    }
    let url =
      profile.name
        .trim()
        .toLowerCase()
        .replace(/[,._+]/g, "")
        .replace(/\s+/g, "-") +
      "/" +
      profile.providerId +
      "/d";
    window.open(configs.SOURCE_URL + url);
  };
  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              <div className="nav-title-txt">Providers</div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    exact={true}
                    to={"/provider/profile/" + match.params.Id}
                  >
                    Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    exact={true}
                    to={
                      "/provider/profile/" +
                      match.params.Id +
                      "/discoveribility"
                    }
                  >
                    Discoverablity score
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                                    <NavLink className="nav-link" exact={true} to={""}>Integration</NavLink>
                                </li> */}
              </ul>
            </div>

            <div className="stp-action">
              <button
                type="button"
                className="btn btn-outline-primary mx-2"
                onClick={previewClick}
              >
                Preview
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  history.push("/doctor/create");
                }}
              >
                <i className="bi bi-plus"></i>
                <span> Add new provider</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      {profile && (
        <section className="provider-profile-sec px-3 pt-4">
          <div className="container">
            <TitleBox profile={profile} fetchProfile={getProfile}></TitleBox>

            <div className="row">
              <div className="col-md-7 col-xl-8">
                {/* Provider description */}
                <Description
                  providerDescription={profile.description}
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                ></Description>

                {/* Provider questions */}
                <Questions
                  practiceQuestions={profile.questionResponse}
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                  questionList={questionList}
                ></Questions>

                <Speciality
                  providerId={profile.providerId}
                  specialityList={specialityList}
                  fetchProfile={getProfile}
                  speciality={profile.specialities}
                ></Speciality>
                {userDataResponse &&
                  userDataResponse.accessEnabled &&
                  userDataResponse.accessEnabled.pms_integration && (
                    <PMSMapping
                      providerId={profile.providerId}
                      fetchProfile={getProfile}
                      sikkaMapping={profile.sikkaMapping}
                      masterPMSProviderList={masterPMSProviderList}
                      specialities={profile.specialities}
                      providerName={profile.name}
                    ></PMSMapping>
                  )}

                <Services
                  providerId={profile.providerId}
                  serviceList={serviceList}
                  selectedService={profile.services}
                  fetchProfile={getProfile}
                ></Services>

                <EducationCertifications
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                  educations={profile.educations}
                  certifications={profile.certificates}
                ></EducationCertifications>

                {/* Block time */}
              </div>
              <div className="col-md-5 col-xl-4">
                <SignInInformaion
                  email={profile.login.email}
                  phone={profile.login.phone}
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                />
                <AcceptNewPatient
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                  acceptPatient={profile.newPatient}
                ></AcceptNewPatient>

                <ShareCalendar
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                  shareCalender={profile.shareCalender}
                  shareUtilityCalender={profile.utilityCalender}
                  shareData={profile.sharedata}
                ></ShareCalendar>

                <GeneralInfo
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                  name={profile.name}
                  email={profile.email}
                  phone={profile.phone}
                  uniqueLIC={profile.licence}
                  gender={profile.gender}
                  selectedLanguages={profile.languages}
                  languageList={languageList}
                ></GeneralInfo>
                <Hours
                  hours_list={profile.hours}
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                ></Hours>

                <BlockTime
                  providerId={profile.providerId}
                  fetchProfile={getProfile}
                  blockTimes={profile.blockTime}
                ></BlockTime>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
