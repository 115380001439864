import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { shareCalendarChange } from "../../services/provider";
export const ShareCalendar = ({
  providerId,
  fetchProfile,
  shareCalender,
  shareUtilityCalender,
  shareData,
}: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, setValue, getValues, handleSubmit, watch } = useForm();
  useEffect(() => {
    if (shareUtilityCalender === "1") {
      setValue("shareUtilityCalender", true);
    } else {
      setValue("shareUtilityCalender", false);
    }
    if (shareCalender === "1") {
      setValue("sharecalendar", true);
      setValue("existingpatient", shareData?.existing);
      setValue("newpatient", shareData?.new);
      setValue("insuredpatient", shareData?.newPatientCriteria?.insured);
      setValue("cashpatient", shareData?.newPatientCriteria?.cash);
      setValue(
        "socialassistancepatient",
        shareData?.newPatientCriteria?.socialAsstistance
      );
    } else {
      setValue("sharecalendar", false);
    }
  }, [shareCalender, shareData]);
  const onCancel = () => {
    setIsEdit(false);
    if (shareUtilityCalender === "1") {
      setValue("shareUtilityCalender", true);
    } else {
      setValue("shareUtilityCalender", false);
    }
    if (shareCalender === "1") {
      setValue("sharecalendar", true);
      setValue("existingpatient", shareData?.existing);
      setValue("newpatient", shareData?.new);
      setValue("insuredpatient", shareData?.newPatientCriteria?.insured);
      setValue("cashpatient", shareData?.newPatientCriteria?.cash);
      setValue(
        "socialassistancepatient",
        shareData?.newPatientCriteria?.socialAsstistance
      );
    } else {
      setValue("sharecalendar", false);
    }
  };
  const onSave = (data: any) => {
    let requestData = {};
    console.log(data);
    if (data.sharecalendar === true) {
      requestData = {
        type: "shareCalender",
        shareCalender: {
          share: 1,
          utility: data.shareUtilityCalender ? 1 : 0,
          sharedata: {
            existing: data.existingpatient,
            new: data.newpatient,
            newPatientCriteria: {
              insured: data.insuredpatient,
              cash: data.cashpatient,
              socialAsstistance: data.socialassistancepatient,
            },
          },
        },
      };
    } else {
      requestData = {
        type: "shareCalender",
        shareCalender: {
          share: 0,
          utility: data.shareUtilityCalender ? 1 : 0,
        },
      };
    }
    console.log(requestData);
    setIsLoading(true);
    shareCalendarChange(providerId, requestData)
      .then((success) => {
        if (success && success.status && success.status.error === false) {
          fetchProfile().then((success: any) => {
            setIsEdit(false);
            setTimeout(() => {
              setIsLoading(false);
              setIsEdit(false);
            }, 2000);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <div className="share-cal-sec mb-4">
      <div className="card">
        <div className="card-header bg-light py-3 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Share Calendar</div>
            <div>
              {!isEdit && !isLoading && (
                <button
                  className="btn p-0 btn-link"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </button>
              )}
              {isEdit && !isLoading && (
                <>
                  <button
                    className="btn p-0 btn-link me-2 text-secondary"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    <i className="bi bi-x-circle-fill text-danger"></i>
                  </button>
                  <button
                    className="btn p-0 btn-link"
                    onClick={handleSubmit(onSave)}
                  >
                    <i className="bi bi-check-circle-fill text-success"></i>
                  </button>
                </>
              )}
              {isEdit && isLoading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
              {isLoading && !isEdit && (
                <i className="bi bi-check-circle-fill text-success"></i>
              )}
            </div>
          </div>
        </div>
        <div className="card-body p-4">
          <div className="d-flex justify-content-between">
            <div>
              <div className="fw-medium">Clinic Website Online Booking</div>
              <small className="text-muted">
                <i className="bi bi-info-circle"></i> Schedule will be shown to
                patients if enabled
              </small>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="shareUtilityCalender"
                name="shareUtilityCalender"
                ref={register}
                disabled={!isEdit}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div>
              <div className="fw-medium">XCarePro Online Booking</div>
              <small className="text-muted">
                <i className="bi bi-info-circle"></i> Schedule will be shown to
                patients if enabled
              </small>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="sharecalender"
                name="sharecalendar"
                ref={register}
                disabled={!isEdit}
              />
            </div>
          </div>

          <div className={"mt-4 " + (watch("sharecalendar") ? "" : "d-none")}>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="existingPatients"
                name="existingpatient"
                ref={register}
                disabled={!isEdit}
              />
              <label className="form-check-label" htmlFor="existingPatients">
                Existing patients
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="newPatients"
                name="newpatient"
                ref={register}
                disabled={!isEdit}
              />
              <label className="form-check-label" htmlFor="newPatients">
                New patients
              </label>
            </div>
          </div>

          <div
            className={
              "mt-4 " +
              (watch("newpatient") ? "" : "d-none") +
              " " +
              (watch("sharecalendar") ? "" : "d-none")
            }
          >
            <div className="title-txt mb-2">New patient criteria</div>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="insuredpatient"
                  ref={register}
                  disabled={!isEdit}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Insured patients
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cashPatients"
                  name="cashpatient"
                  ref={register}
                  disabled={!isEdit}
                />
                <label className="form-check-label" htmlFor="cashPatients">
                  Cash patients
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="socialAssistancePatients"
                  name="socialassistancepatient"
                  ref={register}
                  disabled={!isEdit}
                />
                <label
                  className="form-check-label"
                  htmlFor="socialAssistancePatients"
                >
                  Social assistance patients
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
