import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { IAppState } from "../../../store";
import configs from "../../configs/apiConfigs";
import firebaseInstance from "../../helpers/firebase";
import * as _ from "lodash";
import { createLog } from "../../services/logger";
import moment from "moment";
import SidebarDashboardInactive from "../../assets/images/sidebar-dashboard-inactive.svg";
import SidebarDashboardActive from "../../assets/images/sidebar-dashboard-active.svg";
import SidebarScheduleInactive from "../../assets/images/sidebar-schedule-inactive.svg";
import SidebarScheduleActive from "../../assets/images/sidebar-schedule-active.svg";
import SidebarPMSInactive from "../../assets/images/sidebar-pms-inactive.svg";
import SidebarPMSActive from "../../assets/images/sidebar-pms-active.svg";
import SidebarPaymentInactive from "../../assets/images/sidebar-payments-inactive.svg";
import SidebarPaymentActive from "../../assets/images/sidebar-payments-active.svg";
import SidebarNotificationInactive from "../../assets/images/sidebar-notification-inactive.svg";
import SidebarNotificationActive from "../../assets/images/sidebar-notification-active.svg";
import SidebarTaskIcon from "../../assets/images/task_icon.svg";
import SidebarTaskIconActive from "../../assets/images/task_icon_active.svg";
import SidebarPracticesInactive from "../../assets/images/sidebar-practices-inactive.svg";
import SidebarPractivesActive from "../../assets/images/sidebar-practices-active.svg";
import SidebarProviderInactive from "../../assets/images/sidebar-providers-inactive.svg";
import SidebarProviderActive from "../../assets/images/sidebar-providers-active.svg";
import SidebarSubscriptionInactive from "../../assets/images/sidebar-subscription-inactive.svg";
import SidebarSubscriptionActive from "../../assets/images/sidebar-subscription-active.svg";
import SidebarOfferInactive from "../../assets/images/offer_icon.svg";
import SidebarOfferActive from "../../assets/images/offer_icon_active.svg";
import SidebarHelpInactive from "../../assets/images/sidebar-help-inactive.svg";
import SidebarHelpActive from "../../assets/images/sidebar-help-active.svg";

export const Sidebar = () => {
  const userDataResponse = JSON.parse(
    JSON.stringify(
      useSelector(
        (state: IAppState) => state.userData.userDataResponse,
        shallowEqual
      )
    )
  );
  const [pendingCount, setPendingCount] = useState(0);
  const [providerCount, setProviderCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const location = useLocation();
  useEffect(() => {
    getPendingCount();
  }, []);
  const getPendingCount = async () => {
    // const count = await firebaseInstance.getPendingMessageCount(userDataResponse.clinics[0].uniqueId);
    firebaseInstance.db
      .collection(configs.CONVERSATION_DEV)
      .where("clinicId", "==", Number(userDataResponse.clinics[0].uniqueId))
      .where("metadata.viewByClinicAt", "==", null)
      .onSnapshot(async (querySnapshot: any) => {
        let docs = querySnapshot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
        }));
        docs = _.uniqBy(docs, "dependentUniqueId");
        setPendingCount(docs.length);
      });
    let clinicDoc = await firebaseInstance.db
      .collection(configs.USER_COLLECTION)
      .where("uniqueId", "==", userDataResponse.clinics[0].uniqueId)
      .get();
    let clinicFirebaseId = clinicDoc.docs.map((doc: any) => ({
      ...doc.data,
      id: doc.id,
    }))[0]?.id;
    if (clinicFirebaseId) {
      firebaseInstance.db
        .collection(configs.GROUP_COLLECTION)
        .where("isClosed", "==", false)
        .where("type", "==", 1)
        .where("members", "array-contains", clinicFirebaseId)
        .orderBy("modifiedAt")
        .onSnapshot(async (snapshot: any) => {
          const providers = snapshot.docs.map((item: any) => ({
            ...item.data(),
            id: item.id,
          }));
          let totalCounter = 0;
          for (const provider of providers) {
            let clinicDoc = firebaseInstance.db.collection(
              configs.MESSAGE_COLLECTION
            );
            const chatRefdoc = clinicDoc.doc(provider.id);
            const snapshot = await chatRefdoc.get();
            const data = snapshot.data();
            if (data && data.messages) {
              const messages = data.messages;
              const counter = messages.filter(
                (item: any) =>
                  item.readBy.find((item: any) => {
                    return item.seenBy === clinicFirebaseId;
                  }) === undefined
              );
              if (counter.length > 0) {
                totalCounter += 1;
              }
            }
          }
          setProviderCount(totalCounter);
        });
    }

    if (clinicFirebaseId) {
      firebaseInstance.db
        .collection(configs.GROUP_COLLECTION)
        .where("isClosed", "==", false)
        .where("type", "==", 0)
        .where("members", "array-contains", clinicFirebaseId)
        .orderBy("modifiedAt")
        .onSnapshot(async (snapshot: any) => {
          const providers = snapshot.docs.map((item: any) => ({
            ...item.data(),
            id: item.id,
          }));
          let totalCounter = 0;
          for (const provider of providers) {
            let clinicDoc = firebaseInstance.db.collection(
              configs.MESSAGE_COLLECTION
            );
            const chatRefdoc = clinicDoc.doc(provider.id);
            const snapshot = await chatRefdoc.get();
            const data = snapshot.data();
            if (data && data.messages) {
              const messages = data.messages;
              const counter = messages.filter(
                (item: any) =>
                  item.readBy.find((item: any) => {
                    return item.seenBy === clinicFirebaseId;
                  }) === undefined
              );
              if (counter.length > 0) {
                totalCounter += 1;
              }
            }
          }
          setGroupCount(totalCounter);
        });
    }
  };
  let count = 0;
  count = providerCount + pendingCount + groupCount;
  return (
    <>
      <section className="main-sidebar bg-light shrink">
        {userDataResponse.clinics && userDataResponse.clinics.length > 0 && (
          <div className="profile-sec border rounded d-flex">
            <div className="avatar flex-shrink-0 avatar-lg img-circle me-2">
              <img
                src={
                  configs.IMAGE_URL +
                  "clinic/" +
                  userDataResponse.clinics[0].logo.name +
                  "-200x200." +
                  userDataResponse.clinics[0].logo.type
                }
                className="img-fluid"
              />
            </div>
            <div className="txt-box align-self-center">
              <div
                className="fw-medium charlmt side-bar-name"
                style={{ width: "150px" }}
              >
                {userDataResponse.clinics[0].name}
              </div>
              <small className="text-black-50 side-bar-id">
                ID - {userDataResponse.clinics[0].uniqueId}
              </small>
            </div>
          </div>
        )}
        {userDataResponse?.role !== "agency" ? (
          <div className="sidebar-menu">
            <ul className="p-0 main-menu-list">
              <li className="sidebar-header">
                <small className="text-info">Main Menu</small>
              </li>
              {
                // configs.SOURCE_URL !== "https://www.xcarepro.com/" &&
                <li className="sidebar-items">
                  <NavLink to="/dashboard" className="d-flex">
                    <div className="d-flex">
                      <div className="icon-box me-2">
                        <img
                          src={SidebarDashboardInactive}
                          className="img-fluid inactive-tab"
                        />
                        <img
                          src={SidebarDashboardActive}
                          className="img-fluid active-tab"
                        />
                      </div>
                      <div className="txt-box">
                        <div>Dashboard</div>
                      </div>
                    </div>
                  </NavLink>
                </li>
              }
              <li className="sidebar-items hasSubmenu">
                <NavLink
                  to="/myschedule"
                  className="d-flex navaccordion"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSchedule"
                  isActive={() => {
                    if (location.pathname.includes("/myschedule")) {
                      return true;
                    }
                    return false;
                  }}
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarScheduleInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarScheduleActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div>Clinic Schedule</div>
                  </div>
                </NavLink>

                <ul
                  className="sub-menu-list ms-2 collapse"
                  id="collapseSchedule"
                >
                  <li>
                    <NavLink
                      to="/myschedule/calendar"
                      onClick={() => {
                        createLog({
                          type: "cliniclog",
                          id: userDataResponse.clinics[0].uniqueId,
                          data: {
                            success: "User clicked on Calendar section",
                            action: "click",
                            timestamp: moment().utc().toISOString(),
                          },
                        });
                      }}
                    >
                      Calendar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/myschedule/upcoming">Upcoming</NavLink>
                  </li>
                  <li>
                    <NavLink to="/myschedule/completed">Completed</NavLink>
                  </li>
                  <li>
                    <NavLink to="/myschedule/waitlist">Waitlist</NavLink>
                  </li>
                  <li>
                    <NavLink to="/myschedule/cancelled">Cancelled</NavLink>
                  </li>
                  <li>
                    <NavLink to="/myschedule/all">All</NavLink>
                  </li>
                </ul>
              </li>
              <li className="sidebar-items">
                <NavLink to="/chats" className="d-flex">
                  <div className="icon-box me-2 position-relative">
                    <span className="material-icons">chat</span>
                    {count !== 0 && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary align-self-center sp-badge">
                        {count}
                      </span>
                    )}
                  </div>
                  <div className="txt-box flex-grow-1">
                    <div className="d-block">Chat</div>
                  </div>
                  {count !== 0 && (
                    <span className="badge rounded-pill bg-primary align-self-center">
                      {count}
                    </span>
                  )}
                </NavLink>
              </li>

              <li className="sidebar-items">
                <NavLink
                  to="/pms"
                  className={
                    "d-flex " +
                    (userDataResponse &&
                    userDataResponse.accessEnabled &&
                    userDataResponse.accessEnabled.pms_integration !== true
                      ? "disable-link"
                      : "")
                  }
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarPMSInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarPMSActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box flex-grow-1">
                    <div className="d-block">
                      PMS Appointments{" "}
                      {userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse.accessEnabled.pms_integration !==
                          true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Upgrade your plan.</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </div>
                  </div>
                  {userDataResponse &&
                    userDataResponse.clinics &&
                    Array.isArray(userDataResponse.clinics) &&
                    !isNaN(_.sumBy(userDataResponse.clinics, "unverified")) &&
                    _.sumBy(userDataResponse.clinics, "unverified") > 0 && (
                      <span className="badge rounded-pill bg-primary align-self-center">
                        {_.sumBy(userDataResponse.clinics, "unverified")}
                      </span>
                    )}
                </NavLink>
              </li>
              <li className="sidebar-items d-none">
                <NavLink to="/patientsearch" className="d-flex">
                  <div className="icon-box me-2">
                    <span className="material-icons">person_search</span>
                  </div>
                  <div className="txt-box">
                    <div>Search patient</div>
                  </div>
                </NavLink>
              </li>
              <li className="sidebar-header">
                <small className="text-info">Communication & Payments</small>
              </li>
              <li className="sidebar-items hasSubmenu">
                <NavLink
                  to="/user/payment/all-invoices"
                  className={
                    "d-flex navaccordion " +
                    ((userDataResponse &&
                      userDataResponse.accessEnabled &&
                      userDataResponse?.accessEnabled?.contactless_payments !==
                        true) ||
                    (userDataResponse &&
                      userDataResponse.clinics[0] &&
                      userDataResponse.clinics[0].payment_feature !== true)
                      ? "disable-link"
                      : "")
                  }
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePayments"
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarPaymentInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarPaymentActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div className="d-block">
                      Payments{" "}
                      {userDataResponse &&
                      userDataResponse.accessEnabled &&
                      userDataResponse?.accessEnabled.contactless_payments !==
                        true ? (
                        <OverlayTrigger
                          placement="auto"
                          trigger="hover"
                          overlay={
                            <Tooltip id="tooltip">
                              {"Upgrade your plan."}
                            </Tooltip>
                          }
                        >
                          <i
                            className="bi bi-lock-fill text-danger"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </OverlayTrigger>
                      ) : (
                        userDataResponse &&
                        userDataResponse.clinics[0] &&
                        userDataResponse.clinics[0].payment_feature !==
                          true && (
                          <OverlayTrigger
                            placement="auto"
                            trigger="hover"
                            overlay={<Tooltip id="tooltip">{"Locked"}</Tooltip>}
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )
                      )}
                    </div>
                    {/* <small className="d-block text-black-50">Setup contactless payments</small> */}
                  </div>
                </NavLink>

                <ul
                  className="sub-menu-list ms-2 collapse"
                  id="collapsePayments"
                >
                  <li>
                    <NavLink to="/user/payment/all-invoices">
                      All Invoices
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user/payment/draft">Draft</NavLink>
                  </li>
                  <li>
                    <NavLink to="/user/payment/outstandings">
                      Outstanding
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/user/payment/paid">Paid</NavLink>
                  </li>
                  <li>
                    <NavLink to="/user/payment/stripe-connect">
                      Configuration
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="sidebar-items hasSubmenu">
                <NavLink
                  to="/message-center"
                  className={
                    "d-flex navaccordion " +
                    (userDataResponse &&
                    userDataResponse.accessEnabled &&
                    userDataResponse?.accessEnabled?.account.subscriptions !==
                      true
                      ? "disable-link"
                      : "")
                  }
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMCenter"
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarNotificationInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarNotificationActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div className="d-block">Message Center</div>
                    {/* <small className="d-block text-black-50">Messages & Reminders</small> */}
                  </div>
                </NavLink>

                <ul
                  className="sub-menu-list ms-2 collapse"
                  id="collapseMCenter"
                >
                  <li>
                    <NavLink
                      to="/message-center/notification/reminder"
                      className={
                        userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .appointment_notifications !== true
                          ? "disable-link"
                          : ""
                      }
                    >
                      Reminders
                      {userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .appointment_notifications !== true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Upgrade your plan</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/message-center/followup/reminder"
                      className={
                        userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .appointment_notifications !== true
                          ? "disable-link"
                          : ""
                      }
                    >
                      Follow up
                      {userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .appointment_notifications !== true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Upgrade your plan</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/message-center/campaigns"
                      className={
                        userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .marketing_notifications !== true
                          ? "disable-link"
                          : ""
                      }
                    >
                      Marketing
                      {userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .marketing_notifications !== true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Upgrade your plan.</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/message-center/administrative">
                      Administrative
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/message-center/digitaldoc"
                      className={
                        userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.digitaldoc !== true
                          ? "disable-link"
                          : ""
                      }
                    >
                      Digital Forms
                      {userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.digitaldoc !==
                          true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Upgrade your plan</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/message-center/payment"
                      className={
                        (userDataResponse &&
                          userDataResponse.accessEnabled &&
                          userDataResponse?.accessEnabled
                            ?.contactless_payments !== true) ||
                        (userDataResponse &&
                          userDataResponse.clinics[0] &&
                          userDataResponse.clinics[0].payment_feature !== true)
                          ? "disable-link"
                          : ""
                      }
                    >
                      Payment
                      {userDataResponse &&
                      userDataResponse.accessEnabled &&
                      userDataResponse?.accessEnabled?.contactless_payments !==
                        true ? (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="tooltip">Upgrade your plan</Tooltip>
                          }
                        >
                          <i
                            className="bi bi-lock-fill text-danger"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </OverlayTrigger>
                      ) : (
                        userDataResponse &&
                        userDataResponse.clinics[0] &&
                        userDataResponse.clinics[0].payment_feature !==
                          true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="tooltip">Locked</Tooltip>}
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )
                      )}
                    </NavLink>
                  </li>
                  {userDataResponse &&
                    userDataResponse.clinics &&
                    userDataResponse.clinics[0] &&
                    (userDataResponse.clinics[0].isenableemailtemplate ||
                      userDataResponse.clinics[0]
                        .isenableemailtemplatemarketing) && (
                      <li>
                        <NavLink
                          to="/message-center/templates"
                          className={
                            userDataResponse &&
                            userDataResponse.accessEnabled &&
                            userDataResponse?.accessEnabled
                              ?.contactless_payments !== true
                              ? "disable-link"
                              : ""
                          }
                        >
                          Message templates
                          {userDataResponse &&
                            userDataResponse.accessEnabled &&
                            userDataResponse?.accessEnabled
                              ?.contactless_payments !== true && (
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="tooltip">
                                    Upgrade your plan
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="bi bi-lock-fill text-danger"
                                  style={{ fontSize: "13px" }}
                                ></i>
                              </OverlayTrigger>
                            )}
                        </NavLink>
                      </li>
                    )}
                </ul>
              </li>
              <li className="sidebar-header">
                <small className="text-info">Task Manager</small>
              </li>
              <li className="sidebar-items">
                <NavLink to="/task-manager" className="d-flex">
                  <div className="icon-box me-2 position-relative">
                    <img
                      src={SidebarTaskIcon}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarTaskIconActive}
                      className="img-fluid active-tab"
                    />
                    {Number(userDataResponse.taskCounter) !== 0 && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary align-self-center sp-badge">
                        {Number(userDataResponse.taskCounter)}
                      </span>
                    )}
                  </div>
                  <div className="txt-box flex-grow-1">
                    <div className="align-self-center">Task Manager</div>
                  </div>
                  {Number(userDataResponse.taskCounter) !== 0 && (
                    <span className="badge rounded-pill bg-primary align-self-center">
                      {Number(userDataResponse.taskCounter)}
                    </span>
                  )}
                </NavLink>
              </li>
              <li className="sidebar-header">
                <small className="text-info">My Account</small>
              </li>
              <li className="sidebar-items">
                <NavLink to="/practice/profile" className="d-flex">
                  <div className="icon-box me-2">
                    <img
                      src={SidebarPracticesInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarPractivesActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div className="align-self-center">Practices</div>
                  </div>
                </NavLink>
              </li>
              <li className="sidebar-items">
                <NavLink
                  to="/provider/list"
                  className={
                    userDataResponse &&
                    userDataResponse.userAccess === "0" &&
                    false
                      ? "disable-link d-flex"
                      : "d-flex"
                  }
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarProviderInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarProviderActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div className="align-self-center">
                      Providers
                      {userDataResponse &&
                        userDataResponse.userAccess === "0" &&
                        false && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Permission denied</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </div>
                  </div>
                </NavLink>
                <div className="provider-thumbnails d-none">
                  <div className="avatar-group">
                    {userDataResponse.clinics &&
                      userDataResponse.clinics[0] &&
                      userDataResponse.clinics[0].provider
                        .splice(0, 3)
                        .map((item: any, index: number) => {
                          return (
                            <a
                              href="#"
                              className="avatar rounded-circle"
                              key={item.uniqueid}
                            >
                              {item.photo ? (
                                <img
                                  alt="Image placeholder"
                                  src={
                                    configs.IMAGE_URL +
                                    "doctor/" +
                                    item.photo.name +
                                    "-200x200." +
                                    item.photo.type
                                  }
                                />
                              ) : (
                                <div className="avatar avatar-lg border rounded-circle me-3 text-uppercase">
                                  {item && item.fullname && (
                                    <>
                                      {item.fullname.split(" ")[0] &&
                                        item.fullname.split(" ")[0].charAt(0)}
                                      {item.fullname.split(" ")[1] &&
                                        item.fullname.split(" ")[1].charAt(0)}
                                    </>
                                  )}
                                </div>
                              )}
                            </a>
                          );
                        })}
                  </div>
                </div>
              </li>
              <li className="sidebar-items hasSubmenu">
                <NavLink
                  to="/subscription/overview"
                  className={
                    userDataResponse && userDataResponse.userAccess === "0"
                      ? "disable-link d-flex navaccordion"
                      : "d-flex navaccordion"
                  }
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSubscription"
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarSubscriptionInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarSubscriptionActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div>
                      Subscription
                      {userDataResponse &&
                        userDataResponse.userAccess === "0" && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Permission denied</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </div>
                    {/* <small className="text-black-50">Subscription & billing</small> */}
                  </div>
                </NavLink>
                <ul
                  className="sub-menu-list ms-2 collapse"
                  id="collapseSubscription"
                >
                  <li>
                    <NavLink to="/subscription/overview">Overview</NavLink>
                  </li>
                  <li>
                    <NavLink to="/subscription/plans">Plan</NavLink>
                  </li>
                  <li>
                    <NavLink to="/subscription/manage-plans">
                      Manage plan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/subscription/invoices">Invoices</NavLink>
                  </li>
                  <li>
                    <NavLink to="/subscription/manage-cards">
                      Manage cards
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/subscription/billing-address">
                      Billing address
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="sidebar-header">
                <small className="text-info">Offers</small>
              </li>
              <li className="sidebar-items">
                <NavLink to="/offers" className="d-flex">
                  <div className="icon-box me-2">
                    <img
                      src={SidebarOfferInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarOfferActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div className="align-self-center">Offers</div>
                  </div>
                </NavLink>
              </li>
              <li className="sidebar-header">
                <small className="text-info">Support</small>
              </li>
              <li className="sidebar-items">
                <a
                  href="#"
                  className="d-flex"
                  onClick={(e) => {
                    window.open("https://help.xcarepro.com/");
                  }}
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarHelpInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarHelpActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div>Help</div>
                  </div>
                </a>
              </li>
              <li className="sidebar-items">
                <a href="#" className="d-flex">
                  <div className="icon-box me-2">
                    <img
                      src={SidebarDashboardInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarDashboardActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div>
                      Schedule Call{" "}
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="tooltip">Upgrade your plan</Tooltip>
                        }
                      >
                        <i
                          className="bi bi-lock-fill text-danger"
                          style={{ fontSize: "13px" }}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div className="sidebar-menu">
            <ul className="p-0 main-menu-list">
              <li className="sidebar-header">
                <small className="text-info">Main Menu</small>
              </li>

              <li className="sidebar-items hasSubmenu">
                <NavLink
                  to="/message-center"
                  className={
                    "d-flex navaccordion " +
                    (userDataResponse &&
                    userDataResponse.accessEnabled &&
                    userDataResponse?.accessEnabled?.account.subscriptions !==
                      true
                      ? "disable-link"
                      : "")
                  }
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMCenter"
                >
                  <div className="icon-box me-2">
                    <img
                      src={SidebarNotificationInactive}
                      className="img-fluid inactive-tab"
                    />
                    <img
                      src={SidebarNotificationActive}
                      className="img-fluid active-tab"
                    />
                  </div>
                  <div className="txt-box">
                    <div className="d-block">Message Center</div>
                    {/* <small className="d-block text-black-50">Messages & Reminders</small> */}
                  </div>
                </NavLink>

                <ul
                  className="sub-menu-list ms-2 collapse"
                  id="collapseMCenter"
                >
                  <li>
                    <NavLink
                      to="/message-center/campaigns"
                      className={
                        userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .marketing_notifications !== true
                          ? "disable-link"
                          : ""
                      }
                    >
                      Marketing
                      {userDataResponse &&
                        userDataResponse.accessEnabled &&
                        userDataResponse?.accessEnabled?.message_center
                          .marketing_notifications !== true && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="tooltip">Upgrade your plan.</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-lock-fill text-danger"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </OverlayTrigger>
                        )}
                    </NavLink>
                  </li>
                  {userDataResponse &&
                    userDataResponse.clinics &&
                    userDataResponse.clinics[0] &&
                    (userDataResponse.clinics[0].isenableemailtemplate ||
                      userDataResponse.clinics[0]
                        .isenableemailtemplatemarketing) && (
                      <li>
                        <NavLink
                          to="/message-center/templates"
                          className={
                            userDataResponse &&
                            userDataResponse.accessEnabled &&
                            userDataResponse?.accessEnabled
                              ?.contactless_payments !== true
                              ? "disable-link"
                              : ""
                          }
                        >
                          Message templates
                          {userDataResponse &&
                            userDataResponse.accessEnabled &&
                            userDataResponse?.accessEnabled
                              ?.contactless_payments !== true && (
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id="tooltip">
                                    Upgrade your plan
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="bi bi-lock-fill text-danger"
                                  style={{ fontSize: "13px" }}
                                ></i>
                              </OverlayTrigger>
                            )}
                        </NavLink>
                      </li>
                    )}
                </ul>
              </li>
            </ul>
          </div>
        )}
      </section>
    </>
  );
};
