import React from "react";
import CONSTANT_LABELS from "../../constants/labels";
import { ERROR_CONSTANTS } from "../../constants";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import { LoginState, LoginResponse } from "../../store/login/types";
import { UserInfoRequest, UserInfoState } from "../../store/userinfo/types";
import { fetchUserInfoPending } from "../../store/userinfo/actions";
import { checkIfEmptyOrNull, emailValidator } from "../../utils/validators";
import { getCookie, updateCookie, isLogin } from "../../utils/cookies";
import { fetchLoginSuccess } from "../../store/login/actions";
import { UserEmailRequest, UserEmailState } from "../../store/userEmail/types";
import { fetchUserEmailPending } from "../../store/userEmail/actions";
import {
  UserPasswordRequest,
  UserPasswordState,
} from "../../store/userPassword/types";
import { fetchUserPasswordPending } from "../../store/userPassword/actions";
import Helmet from "react-helmet";
import CONSTANT_TITLES from "../../constants/titles";
import { toast } from "react-toastify";
import {
  UserDataState,
  UserDataRequest,
  EmailVerificationRequest,
} from "../../store/userData/types";
import {
  fetchUserDataPending,
  emailVerificationPendingAction,
} from "../../store/userData/actions";
import SubscriptionPlans from "./SubscriptionPlans";
import { NavLink } from "react-router-dom";
import MyPlans from "./MyPlans";
import BillingAccount from "./billing/BillingAccount";
import MyCards from "./MyCards";
import SubscriptionPlansNew from "../useraccount/SubscriptionPlansNew";
import CardInfo from "../useraccount/CardInfo";
import Overview from "../useraccount/Overview";
interface Props {
  userDataSate: UserDataState;
  userInfo: typeof fetchUserInfoPending;
  userInfoState: UserInfoState;
  login: typeof fetchLoginSuccess;
  userEmail: typeof fetchUserEmailPending;
  userEmailState: UserEmailState;
  userPassword: typeof fetchUserPasswordPending;
  userPasswordState: UserPasswordState;
  userData: typeof fetchUserDataPending;
  emailVerification: typeof emailVerificationPendingAction;
  match: any;
  history: any;
  location: any;
}
interface State {
  firstName: string;
  lastName: string;
  password: string;
  newPassword: string;
  reTypePassword: string;
  email: string;
  showUserInfoSection: boolean;
  showPasswordSection: boolean;
  showEmailSection: boolean;
  emailVerified: string;
  firstNameRequired: boolean;
  lastNameRequired: boolean;
  emailRequired: boolean;
  emailInvalid: boolean;
  lblEmail: string;
  lblName: string;
  passwordRequired: boolean;
  newPasswordRequired: boolean;
  reTypeNewPasswordRequired: boolean;
  passwordDoNotMatch: boolean;
}
class UserAccount extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      password: "",
      newPassword: "",
      reTypePassword: "",
      email: "",
      emailVerified: "",
      showEmailSection: false,
      showPasswordSection: false,
      showUserInfoSection: false,
      firstNameRequired: false,
      lastNameRequired: false,
      emailRequired: false,
      emailInvalid: false,
      lblEmail: "",
      lblName: "",
      passwordRequired: false,
      newPasswordRequired: false,
      reTypeNewPasswordRequired: false,
      passwordDoNotMatch: false,
    };
    this.handleEmailSectionClick = this.handleEmailSectionClick.bind(this);
    this.handlePasswordSectionClick =
      this.handlePasswordSectionClick.bind(this);
    this.handleUserInfoSectionClick =
      this.handleUserInfoSectionClick.bind(this);
    this.handleSaveClickPassword = this.handleSaveClickPassword.bind(this);
  }
  componentDidMount() {
    this.setState({
      lblName:
        this.props.userDataSate.userDataResponse.firstName +
        " " +
        this.props.userDataSate.userDataResponse.lastName,
      lblEmail: this.props.userDataSate.userDataResponse.email,
    });
    /* if (isLogin()) {
            const login: any = getCookie();
            this.props.userData({ id: login.id, uniqueid: login.uniqueId });
        } */
    // this.setState({ firstName: this.props.loginState.loginResponse.firstName });
  }
  handleEmailSectionClick() {
    if (this.state.showEmailSection) {
      this.setState({ showEmailSection: false });
      return;
    }
    this.setState({ showEmailSection: true });
  }
  handleUserInfoSectionClick() {
    if (this.state.showUserInfoSection) {
      this.setState({ showUserInfoSection: false });
      return;
    }
    this.setState({ showUserInfoSection: true });
  }
  handlePasswordSectionClick() {
    if (this.state.showPasswordSection) {
      this.setState({ showPasswordSection: false });
      return;
    }
    this.setState({ showPasswordSection: true });
  }
  onChangeFirstName(e: any) {
    this.setState({ firstName: e.target.value });
    if (checkIfEmptyOrNull(e.target.value)) {
      this.setState({ firstNameRequired: true });
      return;
    }
    this.setState({ firstNameRequired: false });
  }
  onChangeLastName(e: any) {
    this.setState({ lastName: e.target.value });
    if (checkIfEmptyOrNull(e.target.value)) {
      this.setState({ lastNameRequired: true });
      return;
    }
    this.setState({ lastNameRequired: false });
  }
  onChangeEmail(e: any) {
    this.setState({ email: e.target.value });
    if (checkIfEmptyOrNull(e.target.value)) {
      this.setState({ emailRequired: true });
      return;
    }
    this.setState({ emailRequired: false });
  }
  componentWillMount() {
    if (this.props.userDataSate.userDataResponse.email) {
      this.setState({
        lblName:
          this.props.userDataSate.userDataResponse.firstName +
          " " +
          this.props.userDataSate.userDataResponse.lastName,
      });
      this.setState({
        firstName: this.props.userDataSate.userDataResponse.firstName,
        lastName: this.props.userDataSate.userDataResponse.lastName,
        email: this.props.userDataSate.userDataResponse.email,
        emailVerified: this.props.userDataSate.userDataResponse.emailVerified,
      });
    }
  }
  shouldComponentUpdate(nextProps: Props, nextState: any) {
    if (this.props !== nextProps) {
      if (
        this.props.userDataSate.userDataResponse !==
        nextProps.userDataSate.userDataResponse
      ) {
        this.setState({
          lblName:
            nextProps.userDataSate.userDataResponse.firstName +
            " " +
            nextProps.userDataSate.userDataResponse.lastName,
          firstName: nextProps.userDataSate.userDataResponse.firstName,
          lastName: nextProps.userDataSate.userDataResponse.lastName,
          email: nextProps.userDataSate.userDataResponse.email,
          emailVerified: nextProps.userDataSate.userDataResponse.emailVerified,
          lblEmail: nextProps.userDataSate.userDataResponse.email,
        });
      }
      if (nextProps.userDataSate.pending === false) {
        if (
          nextProps.userDataSate.emailVerificationResponse &&
          nextProps.userDataSate.emailVerificationResponse.status.error ===
            false
        ) {
          toast.success(
            nextProps.userDataSate.emailVerificationResponse.status.msg
          );
        }
      }
      if (
        this.props.userInfoState.userInfoResponse !==
        nextProps.userInfoState.userInfoResponse
      ) {
        if (nextProps.userInfoState.userInfoResponse.status.error === false) {
          this.setState({
            lblName:
              nextProps.userInfoState.userInfoResponse.firstName +
              " " +
              nextProps.userInfoState.userInfoResponse.lastName,
            firstName: nextProps.userInfoState.userInfoResponse.firstName,
            lastName: nextProps.userInfoState.userInfoResponse.lastName,
            showUserInfoSection: false,
          });
          toast.success(nextProps.userInfoState.userInfoResponse.status.msg);
        }
      }
      if (this.props.userEmailState !== nextProps.userEmailState) {
        if (nextProps.userEmailState.error !== null) {
          if (
            nextProps.userEmailState.error.status &&
            nextProps.userEmailState.error.status.error === true
          ) {
            toast.error(nextProps.userEmailState.error.status.msg);
          } else {
            toast.error("Something went wrong");
          }
          nextProps.userEmailState.error = null;
        } else if (
          this.props.userEmailState.userEmailResponse !==
            nextProps.userEmailState.userEmailResponse &&
          nextProps.userEmailState.userEmailResponse.status.error === false
        ) {
          this.setState({
            email: nextProps.userEmailState.userEmailResponse.email,
            emailVerified:
              nextProps.userEmailState.userEmailResponse.emailVerified,
            lblEmail: nextProps.userEmailState.userEmailResponse.email,
            showEmailSection: false,
          });
          toast.success(nextProps.userEmailState.userEmailResponse.status.msg);
        }
      }
      if (this.props.userPasswordState !== nextProps.userPasswordState) {
        if (
          nextProps.userPasswordState.userPasswordResponse.status.error ===
            true &&
          nextProps.userPasswordState.userPasswordResponse.status.msg !== ""
        ) {
          // this.setState({ passwordDoNotMatch: true });
          toast.error(
            nextProps.userPasswordState.userPasswordResponse.status.msg
          );
        } else if (
          nextProps.userPasswordState.userPasswordResponse.status.error ===
            false &&
          nextProps.userPasswordState.userPasswordResponse.status.msg !== ""
        ) {
          this.setState({
            passwordDoNotMatch: false,
            showPasswordSection: false,
          });
          toast.success(
            nextProps.userPasswordState.userPasswordResponse.status.msg
          );
        }
      }
      // updateCookie(this.state.firstName, this.state.lastName, this.state.email, this.state.emailVerified);
    }
    return true;
  }
  componentDidUpdate() {
    this.props.userInfoState.userInfoResponse.status.error = true;
    this.props.userEmailState.userEmailResponse.status.error = true;
    this.props.userPasswordState.userPasswordResponse.status.error = true;
    this.props.userPasswordState.userPasswordResponse.status.msg = "";
    if (this.props.userDataSate.emailVerificationResponse) {
      this.props.userDataSate.emailVerificationResponse.status.error = true;
    }
  }
  handleSaveClick(e: any) {
    if (checkIfEmptyOrNull(this.state.firstName)) {
      this.setState({ firstNameRequired: true });
      return;
    } else if (checkIfEmptyOrNull(this.state.lastName)) {
      this.setState({ lastNameRequired: true });
      return;
    }
    this.setState({ firstNameRequired: false, lastNameRequired: false });
    const userInfoReq: UserInfoRequest = {
      // uniqueId: getCookie().uniqueId,
      uniqueId: getCookie().xpr_user_id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    };
    this.props.userInfo(userInfoReq);
  }
  handleSaveClickEmail(e: any) {
    if (checkIfEmptyOrNull(this.state.email)) {
      this.setState({ emailRequired: true });
      return;
    } else if (!emailValidator(this.state.email)) {
      this.setState({ emailRequired: true });
      return;
    }
    const userEmailReq: UserEmailRequest = {
      // uniqueId: getCookie().uniqueId,
      uniqueId: getCookie().xpr_user_id,
      email: this.state.email,
    };
    this.props.userEmail(userEmailReq);
  }
  handleSaveClickPassword(e: any) {
    if (checkIfEmptyOrNull(this.state.password)) {
      this.setState({ passwordRequired: true });
      return;
    } else if (checkIfEmptyOrNull(this.state.newPassword)) {
      this.setState({ newPasswordRequired: true });
      return;
    } else if (checkIfEmptyOrNull(this.state.reTypePassword)) {
      this.setState({ reTypeNewPasswordRequired: true });
      return;
    } else if (this.state.newPassword !== this.state.reTypePassword) {
      this.setState({ passwordDoNotMatch: true });
      return;
    }
    const userPasswordReq: UserPasswordRequest = {
      // uniqueId: getCookie().uniqueId,
      uniqueId: getCookie().xpr_user_id,
      cpassword: this.state.password,
      npassword: this.state.newPassword,
      rpassword: this.state.reTypePassword,
    };
    this.props.userPassword(userPasswordReq);
  }
  onChangePassword(e: any) {
    this.setState({ password: e.target.value });
    if (checkIfEmptyOrNull(e.target.value)) {
      this.setState({ passwordRequired: true });
      return;
    }
    this.setState({ passwordRequired: false });
  }
  onChangeNewPassword(e: any) {
    this.setState({ newPassword: e.target.value });
    if (checkIfEmptyOrNull(e.target.value)) {
      this.setState({ newPasswordRequired: true });
      return;
    }
    this.setState({ passwordDoNotMatch: false });
    this.setState({ newPasswordRequired: false });
  }
  onChangeReTypeNewPassword(e: any) {
    this.setState({ reTypePassword: e.target.value });
    if (checkIfEmptyOrNull(e.target.value)) {
      this.setState({ reTypeNewPasswordRequired: true });
      return;
    }
    this.setState({ passwordDoNotMatch: false });
    this.setState({ reTypeNewPasswordRequired: false });
  }
  handleResendClick() {
    this.props.emailVerification({
      email: this.state.lblEmail,
      modelType: "user",
      modelUniqueid: this.props.userDataSate.userDataResponse.uniqueId,
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            {CONSTANT_TITLES.TITLES.MY_ACCOUNT +
              " - " +
              this.state.lblName +
              " - " +
              CONSTANT_TITLES.TITLES.XCAREPRO}
          </title>
        </Helmet>

        <section className="middle-navbar px-30">
          <div className="p-0 container-fluid">
            <div className="d-flex justify-content-between">
              <div>
                <div className="nav-title-txt">My account</div>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      exact={true}
                      to={"/user/account"}
                    >
                      User account
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="administrator px-3 py-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* <div className="page-header no-gutters has-tab with_tab">
                                    <h2 className="font-weight-normal">My Account</h2>
                                    <ul className="nav nav-tabs sbptab">
                                        <li className="nav-item">
                                            <NavLink exact={true} to={"/user/overview"} >Overview</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink exact={true} to={"/user/plans"} >Plans</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink exact={true} to={"/user/myplans"} >Active plan</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink exact={true} to={"/user/mycards"} >Manage Cards</NavLink>
                                        </li>
                                        {this.props.userDataSate.userDataResponse.userAccess === "1" &&
                                            <li className="nav-item">
                                                <NavLink isActive={() => {
                                                    if (this.props.match.params && this.props.match.params.type === "billing") {
                                                        return true;
                                                    }
                                                    return false;
                                                }} exact={true} to={"/user/billing/appointments"} >Billing</NavLink>
                                            </li>}

                                        <li className="nav-item">
                                            <NavLink exact={true} to={"/user/account"} >Edit Accounts</NavLink>
                                        </li>

                                        <li className="nav-item flex-grow-1 text-right">
                                            <a href="/"><i className="fas fa-arrow-alt-circle-left"></i> Back to Dashboard</a>
                                        </li>
                                    </ul>
                                </div> */}

                {this.props.match.params.type === "account" && (
                  <div className="row">
                    <div className="col-6">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="fs-6 fw-medium">
                              {CONSTANT_LABELS.LABELS.USER_INFO}
                            </div>
                          </div>
                        </div>

                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            {this.state.showUserInfoSection === false && (
                              <div className="d-flex justify-content-between cursor-pointer">
                                <div>{this.state.lblName}</div>
                                <button
                                  className="btn p-0 btn-link"
                                  onClick={this.handleUserInfoSectionClick}
                                >
                                  Edit
                                </button>
                              </div>
                            )}

                            {this.state.showUserInfoSection === true && (
                              <div className="editmod">
                                <div className="row mb-3">
                                  <div className="col">
                                    <label className="form-label">
                                      {CONSTANT_LABELS.LABELS.FIRST_NAME}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.firstName}
                                      onInput={(e) => this.onChangeFirstName(e)}
                                      onChange={(e) =>
                                        this.onChangeFirstName(e)
                                      }
                                    />
                                    {this.state.firstNameRequired === true && (
                                      <div className="is-invalid">
                                        {
                                          CONSTANT_LABELS.REQUIRED
                                            .FIRST_NAME_REQUIRED
                                        }
                                      </div>
                                    )}
                                  </div>
                                  <div className="col">
                                    <label className="form-label">
                                      {CONSTANT_LABELS.LABELS.LAST_NAME}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.lastName}
                                      onInput={(e) => this.onChangeLastName(e)}
                                      onChange={(e) => this.onChangeLastName(e)}
                                    />
                                    {this.state.lastNameRequired && (
                                      <div className="is-invalid">
                                        {
                                          CONSTANT_LABELS.REQUIRED
                                            .LAST_NAME_REQUIRED
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-12 text-end">
                                    <button
                                      type="button"
                                      className="btn p-0 btn-link me-2"
                                      onClick={(e) => {
                                        this.setState({
                                          showUserInfoSection: false,
                                          firstName:
                                            this.props.userDataSate
                                              .userDataResponse.firstName,
                                          lastName:
                                            this.props.userDataSate
                                              .userDataResponse.lastName,
                                        });
                                      }}
                                    >
                                      <i className="bi bi-x-circle-fill text-danger"></i>
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn p-0 btn-link"
                                      onClick={(e) => this.handleSaveClick(e)}
                                    >
                                      <i className="bi bi-check-circle-fill text-success"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                          <li className="list-group-item">
                            {this.state.showEmailSection === false && (
                              <div className="d-flex justify-content-between cursor-pointer">
                                <div>
                                  <div>{this.state.lblEmail}</div>
                                  {this.state.emailVerified !== "" &&
                                    this.state.emailVerified !== null && (
                                      <>
                                        <span className="badge rounded-pill bg-success">
                                          {CONSTANT_LABELS.LABELS.VERIIED}
                                        </span>
                                      </>
                                    )}
                                  {(this.state.emailVerified === "" ||
                                    this.state.emailVerified === null) && (
                                    <>
                                      <span className="badge rounded-pill bg-danger">
                                        {CONSTANT_LABELS.LABELS.UNVERIFIED}
                                      </span>
                                      <button
                                        className="btn btn-sm"
                                        onClick={() => {
                                          this.handleResendClick();
                                        }}
                                      >
                                        {CONSTANT_LABELS.LABELS.RESEND_EMAIL}
                                      </button>
                                    </>
                                  )}
                                </div>
                                <button
                                  className="btn p-0 btn-link"
                                  onClick={this.handleEmailSectionClick}
                                >
                                  Edit
                                </button>
                              </div>
                            )}

                            {this.state.showEmailSection && (
                              <div className="editmod">
                                <div className="row mb-3">
                                  <div className="col">
                                    <label className="form-label">
                                      {CONSTANT_LABELS.LABELS.EMAIL}
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      value={this.state.email}
                                      onInput={(e) => this.onChangeEmail(e)}
                                      onChange={(e) => this.onChangeEmail(e)}
                                    />
                                    {this.state.emailRequired && (
                                      <div className="is-invalid">
                                        {
                                          CONSTANT_LABELS.REQUIRED
                                            .EMAIL_REQUIRED
                                        }
                                      </div>
                                    )}
                                    {this.state.emailInvalid && (
                                      <div className="is-invalid">
                                        {ERROR_CONSTANTS.LABELS.EMAIL}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-12 text-end">
                                    <button
                                      type="button"
                                      className="btn p-0 btn-link me-2"
                                      onClick={(e) => {
                                        this.setState({
                                          showEmailSection: false,
                                          email:
                                            this.props.userDataSate
                                              .userDataResponse.email,
                                        });
                                      }}
                                    >
                                      <i className="bi bi-x-circle-fill text-danger"></i>
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn p-0 btn-link"
                                      onClick={(e) =>
                                        this.handleSaveClickEmail(e)
                                      }
                                    >
                                      <i className="bi bi-check-circle-fill text-success"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card">
                        <div className="card-header">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="fs-6 fw-medium">
                              {CONSTANT_LABELS.LABELS.CHANGE_PASSWORD}
                            </div>
                          </div>
                        </div>

                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            {this.state.showPasswordSection === false && (
                              <div className="d-flex justify-content-between cursor-pointer">
                                <div>*********</div>
                                <button
                                  className="btn p-0 btn-link"
                                  onClick={this.handlePasswordSectionClick}
                                >
                                  Edit
                                </button>
                              </div>
                            )}

                            {this.state.showPasswordSection && (
                              <div className="editmod">
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    <label className="form-label">
                                      {CONSTANT_LABELS.LABELS.CURRENT_PASSWORD}
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={this.state.password}
                                      onInput={(e) => this.onChangePassword(e)}
                                      onChange={(e) => this.onChangePassword(e)}
                                    />
                                    {this.state.passwordRequired && (
                                      <div className="is-invalid">
                                        {
                                          ERROR_CONSTANTS.LABELS
                                            .CURRENT_PASSWORD
                                        }
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12 mb-3">
                                    <label className="form-label">
                                      {CONSTANT_LABELS.LABELS.NEW_PASSWORD}
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={this.state.newPassword}
                                      onInput={(e) =>
                                        this.onChangeNewPassword(e)
                                      }
                                      onChange={(e) =>
                                        this.onChangeNewPassword(e)
                                      }
                                    />
                                    {this.state.newPasswordRequired && (
                                      <div className="is-invalid">
                                        {
                                          ERROR_CONSTANTS.LABELS
                                            .ENTER_NEW_PASSWORD
                                        }
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12 mb-3">
                                    <label className="form-label">
                                      {CONSTANT_LABELS.LABELS.RETYPE_PASSWORD}
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      onInput={(e) =>
                                        this.onChangeReTypeNewPassword(e)
                                      }
                                      value={this.state.reTypePassword}
                                      onChange={(e) =>
                                        this.onChangeReTypeNewPassword(e)
                                      }
                                    />
                                    {this.state.passwordDoNotMatch && (
                                      <div className="is-invalid">
                                        {
                                          ERROR_CONSTANTS.LABELS
                                            .PASSWORD_DONOT_MATCH
                                        }
                                      </div>
                                    )}
                                    {this.state.reTypeNewPasswordRequired && (
                                      <div className="is-invalid">
                                        {
                                          ERROR_CONSTANTS.LABELS
                                            .ENTER_RE_TYPE_NEW_PASSWORD
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-12 text-end">
                                    <button
                                      type="button"
                                      className="btn p-0 btn-link me-2"
                                      onClick={(e) => {
                                        this.setState({
                                          showPasswordSection: false,
                                        });
                                      }}
                                    >
                                      <i className="bi bi-x-circle-fill text-danger"></i>
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn p-0 btn-link"
                                      onClick={this.handleSaveClickPassword}
                                    >
                                      <i className="bi bi-check-circle-fill text-success"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.match.params.type === "overview" && (
                  <Overview history={this.props.history}></Overview>
                )}
                {
                  this.props.match.params.type === "plans" && (
                    // <SubscriptionPlans history={this.props.history}></SubscriptionPlans>
                    <SubscriptionPlansNew
                      history={this.props.history}
                    ></SubscriptionPlansNew>
                  )
                  // <CardInfo></CardInfo>
                }
                {this.props.match.params.type === "myplans" && (
                  <MyPlans history={this.props.history}></MyPlans>
                )}
                {this.props.match.params.type === "billing" && (
                  <BillingAccount
                    match={this.props.match}
                    location={this.props.location}
                    history={this.props.history}
                  ></BillingAccount>
                )}
                {this.props.match.params.type === "mycards" && (
                  <MyCards {...this.props}></MyCards>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  userDataSate: state.userData,
  userInfoState: state.userInfo,
  userEmailState: state.userEmail,
  userPasswordState: state.userPassword,
});
const mapDispatchToProps = (dispatch: any) => ({
  userInfo: (userInfo: UserInfoRequest) => {
    dispatch(fetchUserInfoPending(userInfo));
  },
  login: (login: LoginResponse) => {
    dispatch(fetchLoginSuccess(login));
  },
  userEmail: (userEmail: UserEmailRequest) => {
    dispatch(fetchUserEmailPending(userEmail));
  },
  userPassword: (userPassword: UserPasswordRequest) => {
    dispatch(fetchUserPasswordPending(userPassword));
  },
  userData: (userData: UserDataRequest) => {
    dispatch(fetchUserDataPending(userData));
  },
  emailVerification: (request: EmailVerificationRequest) => {
    dispatch(emailVerificationPendingAction(request));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
