import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo/logo.svg";
import { fetchLoginForgotSentPending } from "../../store/loginforgotsent/actions";
import { IAppState } from "../../store";
import { connect, useDispatch } from "react-redux";
import {
  LABEL_CONSTANTS,
  TITLE_CONSTANTS,
  ERROR_CONSTANTS,
} from "../../constants";
import { emailValidator, checkIfEmptyOrNull } from "../../utils/validators";
import CONSTANT_LABELS from "../../constants/labels";
import { LoginRequest, LoginState } from "../../store/login/types";
import { fetchLoginPending, userLogout } from "../../store/login/actions";
import { Redirect, NavLink, useHistory, useLocation } from "react-router-dom";
import { isLogin, getCookie } from "../../utils/cookies";
import Cookie from "js-cookie";
import apiConfigs from "../../configs/apiConfigs";
import {
  signInWithCustomToken,
  getCurrentUser,
} from "../../utils/firebaseUtils";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../store/loadingIndicator/actions";
import { LoadingState } from "../../store/loadingIndicator/types";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { useForm } from "react-hook-form";
import { login } from "../../v2/services/auth";
/* interface Props {
    changeForgotPassword: typeof Function;
    loginState: LoginState;
    login: typeof fetchLoginPending;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
    logout: typeof userLogout;
    loadingState: LoadingState;
}
interface State {
    emailInput: string;
    passwordInput: string;
    emailInputRequired: boolean;
    passwordInputRequired: boolean;
    emailOrPasswordInvalid: boolean;
    loginSuccess: boolean;
    lblErrorMsg: string;
    alreadyLogin: boolean;
    isLoading: boolean;
}
class Login extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailInput: '',
            passwordInput: '',
            emailInputRequired: false,
            passwordInputRequired: false,
            emailOrPasswordInvalid: false,
            loginSuccess: false,
            lblErrorMsg: ERROR_CONSTANTS.LABELS.INVALID_EMAIL_PASSWORD,
            alreadyLogin: false, isLoading: false
        }
        this.handleLogIn = this.handleLogIn.bind(this);
    }
    handleForgotPassword(e: any) {
        this.props.changeForgotPassword('forgot');
    }
    handleLogIn(e: any) {
        e.preventDefault();
        if (checkIfEmptyOrNull(this.state.emailInput)) {
            this.setState({ emailInputRequired: true });
            return;
        } else if (checkIfEmptyOrNull(this.state.passwordInput)) {
            this.setState({ passwordInputRequired: true });
            return;
        } else if (!emailValidator(this.state.emailInput)) {
            this.setState({ emailOrPasswordInvalid: true });
            return;
        }
        this.setState({ isLoading: true });
        const loginData: LoginRequest = {
            email: this.state.emailInput,
            password: this.state.passwordInput
        }
        this.props.login(loginData)
    }
    handlePasswordChange(e: any) {
        if (this.state.passwordInputRequired) {
            this.setState({ passwordInputRequired: false });
            return;
        } else if (this.state.emailOrPasswordInvalid) {
            this.setState({ emailOrPasswordInvalid: false });
            return;
        }
        this.setState({ passwordInput: e.target.value });
    }
    handleEmailChange(e: any) {
        if (this.state.emailInputRequired) {
            this.setState({ emailInputRequired: false });
            return;
        } else if (this.state.emailOrPasswordInvalid) {
            this.setState({ emailOrPasswordInvalid: false });
            return;
        }
        this.setState({ emailInput: e.target.value });
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props.loginState.loginResponse !== nextProps.loginState.loginResponse) {
            if (nextProps.loginState.loginResponse.status.error === true) {
                this.setState({
                    emailOrPasswordInvalid: nextProps.loginState.loginResponse.status.error,
                    loginSuccess: false, lblErrorMsg: nextProps.loginState.loginResponse.status.msg, isLoading: false
                });
            }
        }
        if (nextProps.loginState.loginResponse &&
            nextProps.loginState.loginResponse !== this.props.loginState.loginResponse &&
            nextProps.loginState.pending === false &&
            nextProps.loginState.loginResponse.status.error === false
            && nextProps.loginState.loginResponse.status.msg !== "") {
            this.props.loadingStart();
            signInWithCustomToken(nextProps.loginState.loginResponse.jwtToken).then((success) => {
                Cookie.remove('clinic_cookie'); Cookie.remove('clinic_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
                const key1 = 'xprUser.clinic-' + nextProps.loginState.loginResponse.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'jwtToken';
                var json: any = {};
                json[key1] = true;
                json[key2] = nextProps.loginState.loginResponse.uniqueId;
                json[key3] = nextProps.loginState.loginResponse.authParam;
                json[key4] = nextProps.loginState.loginResponse.jwtToken;
                Cookie.set('clinic_cookie', json, { expires: 30 });
                Cookie.set('clinic_cookie', json, { domain: apiConfigs.COOKIE_DOMAIN, url: '/', expires: 30 });
                this.props.loadingEnd(false);
                this.setState({ loginSuccess: true, isLoading: false });
            }).catch((error) => {
                this.props.loadingEnd(false);
                this.setState({ alreadyLogin: true, isLoading: false });
            });
        }
        return true;
    }
    componentDidMount() {
        this.props.loginState.loginResponse.status.error = false;
        if (isLogin()) {
            this.props.loadingStart();
            if (!getCurrentUser()) {
                signInWithCustomToken(getCookie().jwtToken).then((signInSuccess) => {
                    this.setState({ alreadyLogin: true });
                    this.props.loadingEnd(false);
                }).catch((error) => {
                    this.props.logout();
                    this.props.loadingEnd(false);
                });
            } else {
                this.props.loadingEnd(false);
                this.setState({ alreadyLogin: true });
            }
        }
    }
    render() {
        if (this.state.loginSuccess === true) {
            return (
                <Redirect to="/"></Redirect>
            )
        } else if (this.state.alreadyLogin) {
            return (
                <Redirect to="/"></Redirect>
            )
        } else {
            return (
                <>
                    <Helmet><title>{TITLE_CONSTANTS.TITLES.LOGIN}</title></Helmet>
                    <div className="card shadow-sm">

                        <div className="card-header bg-light px-4 py-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <img src={logo} alt="Logo" width="100px" />
                                <div className="fs-5 fw-medium">{LABEL_CONSTANTS.LABELS.LOGIN}</div>
                            </div>
                        </div>

                        <div className="card-body p-4">
                            <form onSubmit={this.handleLogIn}>

                                {(this.state.emailOrPasswordInvalid === true) ?
                                    <div className="alert alert-danger py-2" role="alert">
                                        {this.state.lblErrorMsg}
                                    </div>
                                    : <></>
                                }
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="email">{LABEL_CONSTANTS.LABELS.EMAIL}</label>
                                    <input type="email" className="form-control" placeholder="Email address"
                                        value={this.state.emailInput}
                                        onInput={(e) => { this.handleEmailChange(e) }} onChange={(e) => { this.handleEmailChange(e) }} />

                                    {(this.state.emailInputRequired === true && this.state.emailInput === '') ? <div className="is-invalid">{LABEL_CONSTANTS.REQUIRED.EMAIL}</div> : <></>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">{LABEL_CONSTANTS.LABELS.PASSWORD}</label>
                                    <input type="password" className="form-control" id="password" placeholder="Password"
                                        value={this.state.passwordInput}
                                        onInput={(e) => this.handlePasswordChange(e)} onChange={(e) => this.handlePasswordChange(e)} />
                                    {(this.state.passwordInputRequired === true && this.state.passwordInput === '') ? <div className="is-invalid">{LABEL_CONSTANTS.REQUIRED.PASSWORD}</div> : <></>}
                                </div>
                                <div className="">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            <NavLink to="" className="text-decoration-none link-danger" onClick={(e) => this.handleForgotPassword(e)}>{CONSTANT_LABELS.LINK.FORGOT_PASSWORD}</NavLink>
                                        </span>
                                        <button className="btn btn-primary" disabled={this.state.isLoading}>
                                            {
                                                this.state.isLoading &&
                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                            }
                                            {CONSTANT_LABELS.BUTTON_LABEL.LOGIN}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            );
        }
    }
    componentDidUpdate(prevProps: Props, PrevState: State) {
        if (this.props.loginState !== prevProps.loginState) {
            if (this.props.loginState.error !== prevProps.loginState.error && this.props.loginState.error !== null) {
                this.setState({ emailOrPasswordInvalid: true, loginSuccess: false, lblErrorMsg: this.props.loginState.error, isLoading: false });
            }
        }
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    changeForgotPassword: (doShow: string) => {
        dispatch((fetchLoginForgotSentPending({ loginforgotsent: doShow })));
    },
    login: (login: LoginRequest) => {
        dispatch(fetchLoginPending(login));
    },
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
    logout: () => {
        dispatch(userLogout());
    }
});
const mapStateToProps = (state: IAppState) => ({
    loginForgotSentState: state.loginForgotSent,
    loginState: state.login,
    loadingState: state.loading
});
export default connect(mapStateToProps, mapDispatchToProps)(Login); */

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isViewPassword, setIsViewPassword] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, errors, setError } = useForm();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (isLogin()) {
      setIsLoading(true);
      if (!getCurrentUser()) {
        signInWithCustomToken(getCookie().jwtToken)
          .then((signInSuccess) => {
            setIsLoading(false);
            if (
              new URLSearchParams(location.search).get("redirect_to") ===
              "taskmanager"
            ) {
              history.replace("/task-manager");
            } else if (
              new URLSearchParams(location.search).get("redirect_to") ===
              "accountbilling"
            ) {
              history.replace("/subscription/overview");
            } else if (
              new URLSearchParams(location.search).get("redirect_to") ===
              "text2pay"
            ) {
              history.replace("/user/payment/all-invoices");
            } else if (
              new URLSearchParams(location.search).get("redirect_to") ===
              "marketting"
            ) {
              history.replace("/message-center/campaigns/campaigns");
            } else {
              history.replace("/");
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        if (
          new URLSearchParams(location.search).get("redirect_to") ===
          "taskmanager"
        ) {
          history.replace("/task-manager");
        } else if (
          new URLSearchParams(location.search).get("redirect_to") ===
          "accountbilling"
        ) {
          history.replace("/subscription/overview");
        } else if (
          new URLSearchParams(location.search).get("redirect_to") === "text2pay"
        ) {
          history.replace("/user/payment/all-invoices");
        } else if (
          new URLSearchParams(location.search).get("redirect_to") ===
          "marketting"
        ) {
          history.replace("/message-center/campaigns/campaigns");
        } else {
          history.replace("/");
        }
      }
    }
  }, []);
  const onSubmit = (data: any) => {
    setIsLoading(true);
    login({ email: data.email, password: data.password })
      .then((success) => {
        if (success && success.status && success.status.error === false) {
          signInWithCustomToken(success.jwtToken)
            .then((resp) => {
              Cookie.remove("clinic_cookie");
              Cookie.remove("clinic_cookie", {
                domain: apiConfigs.COOKIE_DOMAIN,
              });
              const key1 = "xprUser.clinic-" + success.id;
              const key2 = "xpr_user_id";
              const key3 = "xprAuthUser";
              const key4 = "jwtToken";
              var json: any = {};
              json[key1] = true;
              json[key2] = success.uniqueId;
              json[key3] = success.authParam;
              json[key4] = success.jwtToken;
              Cookie.set("clinic_cookie", json, { expires: 30 });
              Cookie.set("clinic_cookie", json, {
                domain: apiConfigs.COOKIE_DOMAIN,
                url: "/",
                expires: 30,
              });
              setIsLoading(false);
              if (
                new URLSearchParams(location.search).get("redirect_to") ===
                "taskmanager"
              ) {
                history.replace("/task-manager");
              } else if (
                new URLSearchParams(location.search).get("redirect_to") ===
                "accountbilling"
              ) {
                history.replace("/subscription/overview");
              } else if (
                new URLSearchParams(location.search).get("redirect_to") ===
                "text2pay"
              ) {
                history.replace("/user/payment/all-invoices");
              } else if (
                new URLSearchParams(location.search).get("redirect_to") ===
                "marketting"
              ) {
                history.replace("/message-center/campaigns/campaigns");
              } else {
                history.replace("/");
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setError("email", {
                type: "firebaseloginerr",
                message: "We are unable to log you in.",
              });
            });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.error === true) {
          setError("email", { type: "invalidemailpass", message: err.msg });
        } else {
          setError("email", {
            type: "someerr",
            message: "Something went wrong.",
          });
        }
      });
  };
  const handleForgotPassword = (e: any) => {
    dispatch(fetchLoginForgotSentPending({ loginforgotsent: "forgot" }));
  };
  return (
    <>
      <Helmet>
        <title>{TITLE_CONSTANTS.TITLES.LOGIN}</title>
      </Helmet>
      <div className="card shadow-sm">
        <div className="card-header bg-light px-4 py-3">
          <div className="d-flex align-items-center justify-content-between">
            <img src={logo} alt="Logo" width="100px" />
            {/* <div className="fs-5 fw-medium">{LABEL_CONSTANTS.LABELS.LOGIN}</div> */}
          </div>
        </div>

        <div className="card-body p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                {LABEL_CONSTANTS.LABELS.EMAIL}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Email address"
                name="email"
                ref={register({
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors && errors.email && errors.email.type === "required" && (
                <div className="is-invalid">Email is required.</div>
              )}
              {errors && errors.email && errors.email.type === "pattern" && (
                <div className="is-invalid">Email format is invalid.</div>
              )}
              {errors &&
                errors.email &&
                errors.email.type === "invalidemailpass" && (
                  <div className="is-invalid">{errors.email.message}</div>
                )}
              {errors && errors.email && errors.email.type === "someerr" && (
                <div className="is-invalid">{errors.email.message}</div>
              )}
              {errors &&
                errors.email &&
                errors.email.type === "firebaseloginerr" && (
                  <div className="is-invalid">{errors.email.message}</div>
                )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="password">
                {LABEL_CONSTANTS.LABELS.PASSWORD}
              </label>
              <div className="input-group">
                <input
                  type={isViewPassword ? "text" : "password"}
                  disabled={isLoading}
                  className="form-control"
                  name={"password"}
                  ref={register({ required: true })}
                />
                <span className="input-group-text bg-transparent">
                  <div
                    className="view-password-control opacity-50 fs-7"
                    onClick={() => {
                      setIsViewPassword(!isViewPassword);
                    }}
                  >
                    <i
                      className={
                        isViewPassword
                          ? "bi bi-eye active"
                          : "bi bi-eye-slash active"
                      }
                    ></i>
                  </div>
                </span>
              </div>
              {errors &&
                errors.password &&
                errors.password.type === "required" && (
                  <div className="is-invalid">Password is required.</div>
                )}
            </div>
            <div className="">
              <div className="d-flex align-items-center justify-content-between">
                <span>
                  <NavLink
                    to=""
                    className="text-decoration-none link-danger"
                    onClick={(e) => handleForgotPassword(e)}
                  >
                    {CONSTANT_LABELS.LINK.FORGOT_PASSWORD}
                  </NavLink>
                </span>
                <button className="btn btn-primary" disabled={isLoading}>
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {CONSTANT_LABELS.BUTTON_LABEL.LOGIN}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Login;
